const LOAD_SETTING = 'app/snackbar/LOAD_SETTING';
const LOAD_FAILURE = 'app/snackbar/LOAD_FAILURE';
const SET_SNACKBAR_DATA = 'app/snackbar/SET_SNACKBAR_DATA';

function loadSetting(data) {
    return {
        type: LOAD_SETTING,
        payload: {
            items: data
        }
    }
}

function setSnackbarData(data) {
    return {
        type: SET_SNACKBAR_DATA,
        payload: {
            items: data
        }
    }
}

export {
    LOAD_SETTING,
    LOAD_FAILURE,
    SET_SNACKBAR_DATA,
    loadSetting,
    setSnackbarData
}