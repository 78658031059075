// @Utility
import alertText from "@utility/app/alertText";

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";
import { loadHomeData } from "@core/redux/actions/home/index";

const LOAD_ACTIVITY = 'app/activity/LOAD_ACTIVITY';
const LOAD_ACTIVITY_DETAIL = 'app/activity/LOAD_ACTIVITY_DETAIL';
const LOAD_HISTORY_LIST = 'app/activity/LOAD_HISTORY_LIST';
const LOAD_HISTORY_REWARD_LIST = 'app/activity/LOAD_HISTORY_REWARD_LIST';
const LOAD_ACTIVITY_SELECT = 'app/activity/LOAD_ACTIVITY_SELECT';

function loadActivityList() {
    return dispatch => {
        apiServices({}, "get", "/customer/activity/list").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadHistoryList() {
    return dispatch => {
        apiServices({}, "get", "/customer/activity/history").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_HISTORY_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_HISTORY_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadHistoryRewardList() {
    return dispatch => {
        apiServices({}, "get", "/customer/activity/history/reward").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_HISTORY_REWARD_LIST,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_HISTORY_REWARD_LIST,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadActivityDetailData(id) {
    return dispatch => {
        dispatch(setStatusSpinner({ status: true, textProcess: null }));
        dispatch({
            type: LOAD_ACTIVITY_DETAIL,
            payload: {
                items: null
            }
        })
        
        apiServices({}, "get", `/customer/activity/view/${id}`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY_DETAIL,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY_DETAIL,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function loadActivitySelect(id) {
    return dispatch => {
        apiServices({}, "get", `/customer/activity/prediction/register/${id}`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_ACTIVITY_SELECT,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_ACTIVITY_SELECT,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
};

function selectActivityApply(choose_id, activity_id) {
    return dispatch => {
        let myJson = {
            "choose_id": choose_id,
            "activity_id": activity_id
        };

        dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..." }));

        apiServices(myJson, "post", "/customer/activity/prediction/apply").then((data) => {
            const { code: statusCode } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadActivityDetailData(activity_id));
                dispatch(loadActivitySelect(activity_id, []));
                dispatch(setDataAlert({
                    type: "success",
                    title: "เเจ้งเตือน",
                    text: "เข้าร่วมกิจกรรมสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};

function getActivityReward(activity_apply_id, activity_id) {
    return dispatch => {
        let myJson = {
            "activity_apply_id": activity_apply_id
        };

        dispatch(setStatusSpinner({ status: true, textProcess: "ระบบกำลังตรวจสอบข้อมูล กรุณารอสักครู่..." }));

        apiServices(myJson, "post", "/customer/activity/claim").then((data) => {
            const { code: statusCode } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadActivityDetailData(activity_id));
                dispatch(loadHomeData());
                dispatch(setDataAlert({
                    type: "success",
                    title: "เเจ้งเตือน",
                    text: "รับรางวัลสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};


export {
    LOAD_ACTIVITY,
    LOAD_ACTIVITY_DETAIL,
    LOAD_HISTORY_LIST,
    LOAD_HISTORY_REWARD_LIST,
    LOAD_ACTIVITY_SELECT,
    loadActivityList,
    loadActivityDetailData,
    selectActivityApply,
    loadHistoryList,
    loadHistoryRewardList,
    loadActivitySelect,
    getActivityReward
}