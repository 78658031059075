import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Component
import ImageShow from "views/components/ImageShow";

const ProgressiveImage = (props) => {
    // const [loading, setLoading] = useState(true);
    const [currentSrc, setCurrentSrc] = useState(`${window.location.protocol}//${window.location.host}${props.placeholder}`);

    useEffect(() => {
        const { src } = props;
        const imageToLoad = new Image();
        imageToLoad.src = src;
        imageToLoad.onload = () => {
            // setLoading(false);
            setCurrentSrc(src);
        };
    }, [props]);

    return (
        <ImageShow
            src={currentSrc}
            className={["progressiveImage", props.className].join(" ")}
            style={{
                transition: "opacity .15s linear",
                ...props.style
            }}

            ref={props.imageRef}
            onClick={props.onClick}
            altText={props.alt}
        />
    );
}

ProgressiveImage.propTypes = {
    src: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

export default ProgressiveImage;