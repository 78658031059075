import { useState, useEffect } from "react";

// JWT & Auth
import { isUserLoggedIn } from "@core/auth/utils";

// Redux & Action
import { useSelector } from 'react-redux';

// Component
import { Grid } from '@mui/material';
import GameImage from "./GameImage";

export default function GameSearch({ gameList }) {
    // const dispatch = useDispatch();
    const homeData = useSelector(state => state.reduxHome.result);
    const [checkPromotion, setCheckPromotion] = useState(null);

    const renderGameList = () => {
        if (gameList === null) return null;

        return (
            <Grid container spacing={1}>
                {
                    gameList.map((item) => {
                        return (
                            <Grid item xs={4} key={`${item.id}`}>
                                <GameImage item={item} checkPromotion={checkPromotion}></GameImage>
                            </Grid>
                        )
                    })
                }
            </Grid>
        );
    };

    useEffect(() => {
        if (isUserLoggedIn()) {
            if (homeData !== null) {
                setCheckPromotion(homeData.promotion_status);
            }
        }
    }, [homeData]);

    return renderGameList();
}