import {
    SET_DATA_DEMO
} from '../../actions/app/demo';

const initialState = {
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_DATA_DEMO:
            return { ...state, show: action.payload.items };
        default:
            return state;
    }
}