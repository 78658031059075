import {
    SET_PASSWORD_SMS_POPUP
} from '../../actions/app/password';

const initialState = {
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_PASSWORD_SMS_POPUP:
            return { ...state, show: action.payload.items };
        default:
            return state;
    }
}