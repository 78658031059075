const apiDomain = (val) => {
    let domain = "api.";
    let splitDomain = val.split( "." );
    let domainLength = splitDomain.length;

    if (domainLength === 1) {
        domain += splitDomain;
    }
    else if(domainLength === 2) {
        domain += splitDomain[0] + "." + splitDomain[1];
    }
    else {
        let getArrTwoLast = splitDomain.slice(-2);
        domain += getArrTwoLast.slice(-2)[0] + "." + getArrTwoLast.slice(-2)[1];
    }

    return domain+= "/api/v1";
};

export default apiDomain;