// @Utility
import { setStorage } from "@utility/app/storage";

// Services
import { apiServices } from "@core/services/appService";

// Redux
// import { setStatusSpinner } from "@core/redux/actions/app/spinner";

const LOAD_SETTING = 'app/LOAD_SETTING';

function loadSetting(myJson, setDataSettingError, setDataSettingOffline) {
    return dispatch => {
        if (!navigator.onLine) {
            setDataSettingOffline(true);
        }

        if (navigator.onLine) {
            setDataSettingOffline(false);

            apiServices("timeout", "get", `/general/setting/${myJson.domain}`).then((data) => {
                const { code: statusCode, result } = data;
                // dispatch(setStatusSpinner({status: false, textProcess: null}));
    
                if (statusCode === 200 || statusCode === 0) {
                    if(result !== null) {
                        setDataSettingError(false);
                        setStorage("setting", JSON.stringify(result));
                        setStorage("agent_contact", JSON.stringify(result.agent_contact));
                        setStorage("agent_logo", JSON.stringify(result.agent.agent_image));
                    }
                    else {
                        setDataSettingError(true);
                    }
          
                    dispatch({
                        type: LOAD_SETTING,
                        payload: {
                            items: result
                        }
                    });
                }
                else {
                    setDataSettingError(true);
                }
            });
        }
    }
}

export {
    LOAD_SETTING,
    loadSetting
}