// @utility
// import { setStorage, removeStorage } from "@utility/app/storage";
import alertText from "@utility/app/alertText";
import setBodyScroll from '@utility/app/setBodyScroll';

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";
import { setPasswordSmsPopup } from "@core/redux/actions/app/password";
import { loadHomeData } from "@core/redux/actions/home";

const SET_CHANGE_PASSWORD = "app/profile/SET_CHANGE_PASSWORD";
// const CHANGE_PASSWORD_ACTION = "app/profile/CHANGE_PASSWORD_ACTION";

function openChangePassPopup(data) {
    return {
        type: SET_CHANGE_PASSWORD,
        payload: {
            items: data
        }
    }
}

function changePassword(data, pathUrl, passwordSmsContent) {
    return dispatch => {
        // const {
        //     password_old,
        //     password,
        //     password_confirm
        // } = data;

        let myJson = {
            "password_old": data.password_old,
            "password": data.password,
            "password_confirm": data.password_confirm
        };
        
        apiServices(myJson, "post", pathUrl).then((data) => {
            let snackbarJson = {};
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status:false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: "เปลี่ยนรหัสผ่านสำเร็จ !",
                    action: true,
                    redirect: passwordSmsContent === 1 ? null : "/profile",
                    show: true,
                    buttonText: "ตกลง"
                }));
                
                dispatch(openChangePassPopup({ show: false }));
                setBodyScroll(false);

                if(passwordSmsContent) {
                    dispatch(setPasswordSmsPopup(false));
                    dispatch(loadHomeData());
                }
            } else {
                snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: "แจ้งเตือน",
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        })
    }
}

export {
    SET_CHANGE_PASSWORD,
    openChangePassPopup,
    changePassword
}
