import { createStore, applyMiddleware } from 'redux';
import RootReducer from "RootReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import reduxThunk  from 'redux-thunk';

function storeConfig(initialState) {
    const middleware = [reduxThunk];
    const store = createStore(
        RootReducer,
        initialState,
        composeWithDevTools(applyMiddleware(...middleware))
    );

    return store;
}

export default storeConfig;