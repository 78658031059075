import { useEffect, useState } from 'react';

// @utility
import validateInput from "@utility/app/validateInput";

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { setSearchGamePopup, searchGameData, setSearchGameData } from "@core/redux/actions/app/searchGame";

// Component
import Grid from '@mui/material/Grid';
import ModalApp from "./ModalFull";
import GameSearch from "./Game/GameSearch";

// Icon
import Search from '@mui/icons-material/Search';

// Style & CSS
import "assets/css/Input.css";

function SearchGame({ show }) {
    const dispatch = useDispatch();
    const [textData, setTextData] = useState("");
    const gameList = useSelector(state => state.reduxAppSearchGame.gameList);

    const onShow = (val) => {
        dispatch(setSearchGamePopup(val));
    };

    const search = () => {
        if(textData !== "") {
            dispatch(searchGameData(textData));
        }
    };

    useEffect(() => {
        if(show) {
            dispatch(setSearchGameData(null));
        }
    }, [show]);

    return (
        <ModalApp show={show} onShow={onShow}>
            <div className="boxTitle" style={{ color: "var(--bg-white)" }}>
                ค้นหาเกม
            </div>
            <div className="boxMarginTop">
                <Grid container alignItems="center" className="inputStyles inputStylesRadius center">
                    <Grid item xs={2}>
                        <Search className="iconSize" />
                    </Grid>
                    <Grid item xs={10}>
                        <input type="text" className="inputStyles inputStylesBorderNone" placeholder="ค้นหาเกม / ชื่อเกม ที่ต้องการ" onChange={(e) => setTextData(e.target.value)} onKeyPress={(event) => validateInput(event, "textSearch")} maxLength="100" required />
                    </Grid>
                </Grid>
            </div>
            <div className="boxMarginTop center">
                <div className="btn btnConfirm btnHover btnWidthMedium btnRadius textBoldHight" onClick={search}>
                    ค้นหา
                </div>
            </div>
            
            <br />
            <div className="boxMarginTop">
                {
                    gameList !== null && (
                        <GameSearch gameList={gameList}></GameSearch>
                    )
                }
            </div>
        </ModalApp>
    );
}

export default SearchGame;
