import { useRef } from 'react';

// Component
import GotoPage from "views/components/GotoPage";

// Icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function BackButton({ backPage }) {
    const pageRef = useRef();

    const gotoPage = () => {
        if(backPage !== undefined && backPage !== null) {
            if(backPage === "goBack") {
                pageRef.current.goBack();
            }
            else {
                pageRef.current.gotoPage(backPage, "login");
            }
        }
    };

    return (
        <>
            {
                (backPage !== undefined && backPage !== null) && (
                    <GotoPage ref={pageRef}></GotoPage>
                )
            }

            <ArrowBackIcon className="iconBackPage cursorPointer positionRelative" onClick={() => gotoPage()}></ArrowBackIcon>
        </>
    );
}