import {
    LOAD_DATA_CAMP_ALL,
    SET_SELECT_CAMP
} from './../../actions/camp/index';

const initialState = {
    result: null,
    selectCamp: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_CAMP_ALL:
            return { ...state, result: action.payload.items };
        case SET_SELECT_CAMP:
            return { ...state, selectCamp: action.payload.items };
        default:
            return state;
    }
}