import { useEffect } from 'react';

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { setPasswordSmsPopup } from "@core/redux/actions/app/password";

// Component
import AuthApp from "views/components/Auth/index";
import AlertApp from "views/components/Alert/index";
import SnackbarApp from "views/components/Alert/Snackbar";
import DemoApp from "views/components/Demo";
import SearchGame from "views/components/SearchGame";
import ImagePopup from "views/components/ImagePopup";
import PasswordSms from "views/components/PasswordSms";

export default function ConfigSet() {
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const showAuthen = useSelector(state => state.reduxAuth.show);
    const snackbarData = useSelector(state => state.reduxAppSnackbar.snackbarData);
    const alertData = useSelector(state => state.reduxAppAlert);
    const demoData = useSelector(state => state.reduxAppDemo);
    const reduxSearchGame = useSelector(state => state.reduxAppSearchGame?.show);
    const reduxAppPassword = useSelector(state => state.reduxAppPassword?.show);
    const homeData = useSelector(state => state.reduxHome.result);
    const dispatch = useDispatch();
    
    useEffect(() => {
        if(homeData !== null) {
            if(homeData?.auth_sms !== undefined && homeData?.auth_sms !== null) {
                if(homeData?.auth_sms === 1 || homeData?.auth_sms === true) {
                      dispatch(setPasswordSmsPopup(true));
                }
            }
        }
    }, [homeData]);

    return (
        <>
            {
                showAuthen && (
                    <AuthApp></AuthApp>
                )
            }

            {
                !showAuthen && (
                    <ImagePopup agentImage={dataSetting?.agent_image}></ImagePopup>
                )
            }

            {
                reduxSearchGame && (
                    <SearchGame show={reduxSearchGame}></SearchGame>
                )
            }

            {
                reduxAppPassword && (
                    <PasswordSms show={reduxAppPassword}></PasswordSms>
                )
            }

            {
                snackbarData.snackbarShow && (
                    <SnackbarApp snackbarData={snackbarData}></SnackbarApp>
                )
            }

            {
                alertData.show && (
                    <AlertApp alertData={alertData}></AlertApp>
                )
            }

            {
                demoData.show && (
                    <DemoApp demoData={demoData}></DemoApp>
                )
            }
        </>
    );
}