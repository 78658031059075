import { useState, useEffect } from 'react';

export default function ImageShow({ className, style, typeUrl, src, altText, onClick }) {
    const [urlImage, setUrlImage] = useState("");

    useEffect(() => {
        setUrlImage(src);
    }, [typeUrl, src]);

    return (
        urlImage !== "" && (
            <img className={className} style={style} src={urlImage} alt={altText} onClick={onClick} />
        )
    );
}