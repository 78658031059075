import { useState } from 'react';
import { useForm } from 'react-hook-form';

// @utility
import validateInput from "@utility/app/validateInput";

// Component
import Grid from '@mui/material/Grid';

// Icon
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import LockIcon from '@mui/icons-material/Lock';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

export default function LoginApp(props) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [password, setPassword] = useState({
        password: "",
        showPassword: false
    });

    // แสดงหรือซ่อนรหัสผ่าน
    const handleClickShowPassword = () => {
        setPassword({ ...password, showPassword: !password.showPassword });
    };

    const renderErrorUsername = () => {
        if (errors.username?.type === "pattern") {
            return (
                <div className="validateError">
                    ยูสเซอร์เนมที่คุณระบุมีอักขระพิเศษ
                </div>
            );
        }

        return null;
    };

    const renderErrorPassword = () => {
        if (errors.password?.type === "minLength") {
            return (
                <div className="validateError">
                    กรุณาระบุรหัสผ่านมากกว่า 4 ตัวอักษร
                </div>
            );
        }

        return null;
    };

    return (
        !props.process ?
            <>
                <form onSubmit={handleSubmit(props.authenSubmit)}>
                    <div className="inputPaddingTop">
                        <br />
                        <div className="inputTextLabel">
                            เบอร์โทรศัพท์
                        </div>
                        <Grid container alignItems="center" className="inputStyles center">
                            <Grid item xs={2}>
                                <PhoneIcon className="iconSize" />
                            </Grid>
                            <Grid item xs={10}>
                                <input type="text" className="inputStyles inputStylesBorderNone" placeholder="เบอร์โทรศัพท์" {...register("username", { required: true, maxLength: 30, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="30" required />
                            </Grid>
                        </Grid>
                        {
                            renderErrorUsername()
                        }
                    </div>
                    <div className="inputPaddingTop">
                        <div className="inputTextLabel">
                            รหัสผ่าน
                        </div>
                        <Grid container alignItems="center" className="inputStyles center">
                            <Grid item xs={2}>
                                <LockIcon className="iconSize" />
                            </Grid>
                            <Grid item xs={8}>
                                <input type={password.showPassword ? 'text' : 'password'} className="inputStyles inputStylesBorderNone" placeholder="รหัสผ่าน"  {...register("password", { required: true, maxLength: 20, minLength: 4, pattern: /^[A-Za-z0-9]+$/i })} onKeyPress={(event) => validateInput(event, "textNumber")} maxLength="20" required />
                            </Grid>
                            <Grid item xs={2}>
                                <div
                                    onClick={handleClickShowPassword}
                                >
                                    {password.showPassword ? <Visibility className="iconSize" /> : <VisibilityOff className="iconSize" />}
                                </div>
                            </Grid>
                        </Grid>
                        {
                            renderErrorPassword()
                        }
                    </div>
                    <div className="inputPaddingTop right">
                        <div className="inputTextLabel" style={{ paddingBottom: "0px", borderBottom: "1px solid white", display: "inline-block", paddingLeft: "0px", color: "white", cursor: "pointer" }} onClick={() => { props.switchChanel("remember") }}>
                            ลืมรหัสผ่านใช่หรือไม่ ?
                        </div>
                    </div>


                    <div className="center">
                        <div className="inputPaddingTop" style={{ paddingTop: "10px" }}>
                            <button type="submit" className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight">
                                เข้าสู่ระบบ
                            </button>
                        </div>
                    </div>
                </form>
                <div className="boxMarginTop center">
                    <div className="inputNotRegister">
                        ยังไม่ได้เป็นสมาชิก?
                    </div>

                    <div className="boxMarginTop">
                        <button className="btn btnLogin btnHover btnWidthLarge btnRadius textBoldHight" onClick={() => { props.switchChanel("register") }}>
                            สมัครสมาชิก
                        </button>
                    </div>
                </div>
            </>
            :
            null
    );
}