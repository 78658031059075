// Component
import ImageShow from "views/components/ImageShow";

export default function Offline() {
    window.scrollTo({
        top: 0,
        left: 0
    });

    return <ImageShow className="imgError" src="/images/error/offline.jpg" alt="สล็อตออนไลน์" />;
}