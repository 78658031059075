import { combineReducers } from "redux";

// Redux src
import reduxAuth from "@core/redux/reducers/auth/index";
import reduxAppSnackbar from "@core/redux/reducers/app/snackbar";
import reduxAppDataSetting from "@core/redux/reducers/app/dataSetting";
import reduxCamp from "@core/redux/reducers/camp/index";
import reduxAppSpinner from "@core/redux/reducers/app/spinner";
// import reduxAppDropdown from "../@core/redux/reducers/app/dropdrown";
import reduxGame from "@core/redux/reducers/game/index";
import reduxHome from "@core/redux/reducers/home/index";
// import reduxBank from "./../@core/redux/reducers/bank/index";
// import reduxAppDialog from "./../@core/redux/reducers/app/dialog";
import reduxDeposit from "@core/redux/reducers/deposit/index";
import reduxWithdraw from "@core/redux/reducers/withdraw/index";
import reduxAffiliate from "@core/redux/reducers/affiliate/index";
import reduxCashback from "@core/redux/reducers/cashback/index";
// import reduxAppCondition from "./../@core/redux/reducers/app/conditions";
// import reduxAppContact from "./../@core/redux/reducers/app/contact";
// import reduxAppPopup from "../@core/redux/reducers/app/popup";
// import reduxProfile from "../@core/redux/reducers/profile";
// import reduxActivity from "../@core/redux/reducers/activity";
// import reduxAppConfirm from "../@core/redux/reducers/app/confirm";
import reduxAppAlert from "@core/redux/reducers/app/alert";
// import reduxShop from "../@core/redux/reducers/shop/index";
import reduxPromotion from "@core/redux/reducers/promotion/index";
// import reduxCheckPromotion from "../@core/redux/reducers/promotion/checkPromotion";
import reduxAppScrollPosition from "@core/redux/reducers/app/scrollPosition";
// import reduxAppProcessing from "../@core/redux/reducers/app/processing";
import reduxAppCMS from "@core/redux/reducers/app/cms";
import reduxAppDemo from "@core/redux/reducers/app/demo";
import reduxChangePass from "@core/redux/reducers/profile/changepass";
import reduxHistory from "@core/redux/reducers/history/index";
import reduxAppSearchGame from "@core/redux/reducers/app/searchGame";
import reduxAppWheel from "@core/redux/reducers/app/wheel";
import reduxActivity from "@core/redux/reducers/activity";
import reduxAppPassword from "@core/redux/reducers/app/password";

export default combineReducers({
    reduxAuth,
    reduxAppDataSetting,
    reduxCamp,
    reduxAppSnackbar,
    // reduxAppMenu,
    reduxGame,
    reduxHome,
    // reduxBank,
    // reduxAppDialog,
    reduxDeposit,
    reduxAppSpinner,
    reduxWithdraw,
    reduxAffiliate,
    reduxCashback,
    // reduxAppDropdown,
    // reduxAppCondition,
    // reduxAppContact,
    // reduxAppPopup,
    // reduxProfile,
    // reduxActivity,
    // reduxAppConfirm,
    reduxAppAlert,
    // reduxShop,
    reduxPromotion,
    // reduxCheckPromotion,
    reduxAppScrollPosition,
    // reduxAppProcessing,
    reduxAppCMS,
    reduxChangePass,
    reduxHistory,
    reduxAppDemo,
    reduxAppSearchGame,
    reduxAppWheel,
    reduxActivity,
    reduxAppPassword
});