import { useEffect, useState } from 'react';

// Redux & Action
import { useSelector } from 'react-redux';

// @Utility
import setBodyScroll from "@utility/app/setBodyScroll";

// Style & CSS
import "assets/css/Modal.css";

// Icon
import CloseIcon from '@mui/icons-material/Close';

export default function ModalFull({ show, onShow, children }) {
    const homeData = useSelector(state => state.reduxHome.result);
    const [passwordSmsContent, setPasswordSmsContent] = useState(null);

    const close = () => {
        if (passwordSmsContent !== 1) {
            setBodyScroll(false);
            onShow(false);
        }
    };

    useEffect(() => {
        setBodyScroll(show);
    }, [show]);

    useEffect(() => {
        if (homeData !== null) {
            if (homeData?.auth_sms !== undefined && homeData?.auth_sms !== null) {
                if (homeData?.auth_sms === 1 || homeData?.auth_sms === true) {
                    setPasswordSmsContent(1);
                }
            }
        }
    }, [homeData]);

    return (
        show && (
            <div id="modal">
                <div className="contentPosition  zIndexHight">
                    <div className="boxBodyFull">
                        <div className="boxFull">
                            {
                                passwordSmsContent !== 1 && (
                                    <div className="positionRelative">
                                        <div className="closeButtonFull" onClick={() => close(false)}>
                                            <CloseIcon className="iconImgFull"></CloseIcon>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="boxContentFull">
                                {
                                    children
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="fixedBackdrop zIndexMedium" onClick={close}></div>
            </div>
        )
    );
}