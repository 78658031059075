import {
    SET_SEACH_GAME_POPUP,
    SET_SEACH_GAME_DATA
} from '../../actions/app/searchGame';

const initialState = {
    show: false,
    gameList: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEACH_GAME_POPUP:
            return { ...state, show: action.payload.items };
        case SET_SEACH_GAME_DATA:
            return { ...state, gameList: action.payload.items };
        default:
            return state;
    }
}