import alertText from "@utility/app/alertText";


// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setSnackbarData } from "@core/redux/actions/app/snackbar";

const LOAD_HISTORY_DATA = 'app/history/LOAD_HISTORY_DATA';

function loadTableData(path, limit, offset, date_start, date_end) {
    return dispatch => {
        let myJson = {
            limit,
            offset,
            date_start,
            date_end
        };
        apiServices(myJson, "post", `/customer/history/${path}`).then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));
            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_HISTORY_DATA,
                    payload: {
                        items: result
                    }
                });

            }
        })
    }
}

export {
    LOAD_HISTORY_DATA,
    loadTableData
}