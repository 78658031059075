// @Utility
import alertText from "@utility/app/alertText";

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setDataAlert } from "@core/redux/actions/app/alert";

const LOAD_DATA_CASHBACK = 'app/cashback/SET_DATA_CASHBACK';

function loadCashbackData() {
    return dispatch => {
        apiServices({}, "get", "/customer/property/list").then((data) => {
            const { code: statusCode, result } = data;
    
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_CASHBACK,
                    payload: {
                        items: result
                    }
                })
            }
        });
    }
};

function claimAll(path, type, typeAction) {
    return dispatch => {
        dispatch(setStatusSpinner({status: true, textProcess: null}));

        let myJson = {
            "game_camp_type": type
        };

        apiServices(myJson, "post", path).then((data) => {
            const { code: statusCode } = data;
    
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(setDataAlert({
                    type: "success",
                    title: "แจ้งเตือน",
                    text: typeAction === "cashback" ? "รับโบนัสยอดเสียสำเร็จ !" : "รับโบนัสค่าคอมสำเร็จ !",
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));

                dispatch(loadCashbackData());
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
};

export {
    LOAD_DATA_CASHBACK,
    loadCashbackData,
    claimAll
}