import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// @Utility
import copyText from "@utility/app/copyText";

// Redux & Action
import { useDispatch } from 'react-redux';
import { setDataAlert } from "@core/redux/actions/app/alert";
import { openAuthenApp } from "@core/redux/actions/auth/index";

// Component
import Grid from '@mui/material/Grid';
import ModalAlert from "./ModalAlert";

// Icon
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const Alert = ({ alertData }) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [dataObj, setDataObj] = useState(null);

    const onShow = (val) => {
        if (val === false) {
            dispatch(setDataAlert({
                type: null,
                title: "",
                text: "",
                action: false,
                redirect: "",
                show: false,
                buttonText: ""
            }));
        }
        else {
            dispatch(setDataAlert({ ...alertData, show: val }));
        }
    };

    const renderSVGAlert = (type) => {
        if (type === "wheelSpin") {
            return (
                <div className="img-box center">
                    {
                        alertData?.image !== undefined && (
                            <img className="img-wheel-reward" src={alertData?.image} alt=" เว็บตรง ลิขสิทธิ์แท้ ไม่ผ่านเอเย่นต์ คาสิโน สล็อต กีฬา  ฝาก - ถอน ระบบอัตโนมัติ 24 ชม" />
                        )
                    }
                </div>
            );
        }
        else {
            if (type === "success") {
                return (
                    <div className="svg-box">
                        <svg className="circular green-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                        </svg>
                        <svg className="checkmark green-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-489.57,-205.679)">
                                <path className="checkmark__check" fill="none" d="M616.306,283.025L634.087,300.805L673.361,261.53" />
                            </g>
                        </svg>
                    </div>
                );
            }
            else if (type === "warning") {
                return (
                    <div className="svg-box">
                        <svg className="circular yellow-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                        </svg>
                        <svg className="alert-sign yellow-stroke">
                            <g transform="matrix(1,0,0,1,-615.516,-257.346)">
                                <g transform="matrix(0.56541,-0.56541,0.56541,0.56541,93.7153,495.69)">
                                    <path className="line" d="M634.087,300.805L673.361,261.53" fill="none" />
                                </g>
                                <g transform="matrix(2.27612,-2.46519e-32,0,2.27612,-792.339,-404.147)">
                                    <circle className="dot" cx="621.52" cy="316.126" r="1.318" />
                                </g>
                            </g>
                        </svg>
                    </div>
                );
            }
            else if (type === "error") {
                return (
                    <div className="svg-box">
                        <svg className="circular red-stroke">
                            <circle className="path" cx="75" cy="75" r="50" fill="none" strokeWidth="5" strokeMiterlimit="10" />
                        </svg>
                        <svg className="cross red-stroke">
                            <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                                <path className="first-line" d="M634.087,300.805L673.361,261.53" fill="none" />
                            </g>
                            <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                                <path className="second-line" d="M634.087,300.805L673.361,261.53" />
                            </g>
                        </svg>
                    </div>
                );
            }
        }

        return null;
    };

    const clickAction = () => {
        if (alertData.redirect === null) {
            onShow(false);
        }
        else {
            if (alertData.redirect === "/login") {
                openLogin();
            }
            else {
                onShow(false);
                history(alertData.redirect);
            }
        }
    };

    const openLogin = () => {
        let myObj = {
            tab: "login",
            show: true
        };

        onShow(false);
        dispatch(openAuthenApp(myObj));
    };

    const copyData = (data) => {
        if (copyText(data)) {
            onShow(false);

            setTimeout(() => {
                let snackbarJson = {
                    statusShow: true,
                    statusAlert: "success",
                    titleAlert: "แจ้งเตือน",
                    textAlert: "คัดลอกรหัสผ่านสำเร็จ !"
                };

                openBox(snackbarJson);
            }, 200);
        }
    };

    const openBox = (snackbarJson) => {
        dispatch(setDataAlert({
            type: snackbarJson.statusAlert,
            title: snackbarJson.titleAlert,
            text: snackbarJson.textAlert,
            action: true,
            redirect: null,
            show: true,
            buttonText: "ตกลง"
        }));
    };

    useEffect(() => {
        if (alertData.action !== true) {
            if (alertData.action?.select !== undefined) {
                setDataObj(alertData.action);
            }
        }
    }, [alertData.action]);

    return (
        <ModalAlert show={alertData.show} onShow={onShow}>
            <div className="boxTitle center" style={{ color: "var(--bg-white)" }}>
                {alertData.title}
            </div>

            <Grid container justifyContent="center">
                <Grid item>
                    {
                        renderSVGAlert(alertData.type)
                    }
                </Grid>
            </Grid>

            {
                alertData.text !== null && (
                    <div className="contentMarginTop center alertMarginTop">
                        {
                            alertData.type === "none" ?
                                <div className="confirmTitle">
                                    {alertData.text}
                                </div>
                                :
                                <div className="confirmText">
                                    {alertData.text}
                                </div>
                        }
                    </div>
                )
            }

            {
                dataObj !== null && (
                    dataObj.select === "register" && (
                        <div className="center contentMarginTop">
                            <div className="boxRegisterAction left displayInlineBlock textBoldHight">
                                ยูสเซอร์เนม : <span style={{ color: "var(--bg-primary)" }}> {dataObj?.username} </span>
                            </div>
                            <div className="boxRegisterAction left displayInlineBlock textBoldHight">
                                รหัสผ่าน : <span style={{ color: "var(--bg-primary)" }}> {dataObj?.password} </span>
                            </div>

                            <div className="boxMarginTop">
                                <div className="center">
                                    <button className="btn btnConfirm btnHover btnWidthHight btnRadius textBoldHight" onClick={() => copyData(dataObj?.password)}>
                                        <Grid container direction="row" alignItems="center">
                                            <Grid item xs={3}>
                                                <div className="right">
                                                    <ContentCopyIcon style={{ marginTop: "4px" }}></ContentCopyIcon>
                                                </div>
                                            </Grid>
                                            <Grid item xs={7}>
                                                คัดลอกรหัสผ่าน
                                            </Grid>
                                            <Grid item xs={2}>
                                                &nbsp;
                                            </Grid>
                                        </Grid>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                )
            }

            {
                (alertData.action === true) && (
                    <div className="boxMarginTop">
                        <div className="center">
                            <button className="btn btnConfirm btnHover btnWidthLarge btnRadius textBoldHight" onClick={clickAction}>
                                {alertData.buttonText}
                            </button>
                        </div>
                    </div>
                )
            }

        </ModalAlert>
    );
}

export default Alert;