// @utility
import { setStorage, removeStorage } from "@utility/app/storage";
import alertText from "@utility/app/alertText";

// Services
import { apiServices } from "@core/services/appService";

// Redux
import { setStatusSpinner } from "@core/redux/actions/app/spinner";
import { setSnackbarData } from "@core/redux/actions/app/snackbar";
import { loadHomeData } from "@core/redux/actions/home/index";
import { setDataAlert } from "@core/redux/actions/app/alert";

const LOAD_DATA_WITHDRAW = 'app/withdraw/SET_WITHDRAW_DATA';
const SET_WITHDRAW_RANK_DATA = 'app/withdraw/SET_WITHDRAW_RANK_DATA';

function setWithdrawData(data) {
    return {
        type: LOAD_DATA_WITHDRAW,
        payload: {
            items: data
        }
    }
}

function loadDataWithdraw() {
    return dispatch => {
        apiServices({}, "get", "/customer/payment/withdraw").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({status: false, textProcess: null}));

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: LOAD_DATA_WITHDRAW,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: LOAD_DATA_WITHDRAW,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function setWithdrawRankData() {
    return dispatch => {
        apiServices({}, "get", "/common/section/withdraw").then((data) => {
            const { code: statusCode, result } = data;

            if (statusCode === 200 || statusCode === 0) {
                dispatch({
                    type: SET_WITHDRAW_RANK_DATA,
                    payload: {
                        items: result
                    }
                })
            }
            else {
                dispatch({
                    type: SET_WITHDRAW_RANK_DATA,
                    payload: {
                        items: null
                    }
                })
            }
        });
    }
}

function confirmWithdraw(data) {
    return dispatch => {
        const {
            amount,
            bankId
        } = data;
    
        let myJson = {
            "amount": amount,
            "bankId": bankId
        };

        dispatch(setStatusSpinner({status: true, textProcess: null}));
        apiServices(myJson, "post", "/customer/payment/withdraw/automatic").then((data) => {
            const { code: statusCode, result } = data;
            dispatch(setStatusSpinner({ status: false, textProcess: null }));

            if (statusCode === 200 || statusCode === 0) {
                dispatch(loadHomeData());
                dispatch(setWithdrawData(result));
                setStorage("processWithdraw", 1);
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setDataAlert({
                    type: snackbarJson.statusAlert,
                    title: snackbarJson.titleAlert,
                    text: snackbarJson.textAlert,
                    action: true,
                    redirect: null,
                    show: true,
                    buttonText: "ตกลง"
                }));
            }
        });
    }
}

function checkWithdraw(checkTime, withdrawId, typePage) {
    return dispatch => {
        apiServices({"id": withdrawId}, "post", "/customer/payment/withdraw/check").then((data) => {
            const { code: statusCode } = data;

            if (statusCode === 200 || statusCode === 0) {
                switch (data.result.status) {
                    case 0:
                    case 1: ;
                    case 2: break;
                    case 3:

                        if(typePage === "withdraw") {
                            dispatch(setDataAlert({
                                type: "success",
                                title: "แจ้งเตือน",
                                text: "ทำรายการถอนเงินสำเร็จ !",
                                action: true,
                                redirect: null,
                                show: true,
                                buttonText: "ตกลง"
                            }));
                        }
                        else {
                            dispatch(setSnackbarData({
                                statusShow: true,
                                statusAlert: "success",
                                titleAlert: "แจ้งเตือน",
                                textAlert: "ทำรายการถอนเงินสำเร็จ !"
                            }));
                        }

                        dispatch(loadDataWithdraw());
                        dispatch(loadHomeData());
                        clearInterval(checkTime);
                        removeStorage("processWithdraw");
                        break;
                    case 4:

                        dispatch(setDataAlert({
                            type: "warning",
                            title: "แจ้งเตือน",
                            text: "ยกเลิกรายการถอนเงิน เนื่องจากมียอดไม่ถูกต้อง กรุณาติดต่อแอดมิน !",
                            action: true,
                            redirect: null,
                            show: true,
                            buttonText: "ตกลง"
                        }));

                        dispatch(loadHomeData());
                        dispatch(loadDataWithdraw());
                        clearInterval(checkTime);
                        removeStorage("processWithdraw");
                        break;
                    default:
                        clearInterval(checkTime);
                        removeStorage("processWithdraw");
                        break;
                }
            }
            else {
                let snackbarJson = alertText(statusCode);
                dispatch(setSnackbarData(snackbarJson));
            }
        });
    }
}

export {
    LOAD_DATA_WITHDRAW,
    SET_WITHDRAW_RANK_DATA,
    setWithdrawData,
    loadDataWithdraw,
    confirmWithdraw,
    checkWithdraw,
    setWithdrawRankData
}