import axios from './aixos';
import serviceConfig from "@core/services/serviceConfig";
import { getToken } from "@core/auth/utils";
import { checkStatusCode } from "./services";

const queryString = require('query-string');
const svConfig = { ...serviceConfig };

const apiServices = async (myJson, method, path, version = "") => {
  if (!navigator.onLine) {
    return {
      code: 101,
      result: null
    };
  }

  try {
    let config = {};
    let methodType = method === "file" ? "post" : method;
    let dataRequest = method === "file" ? myJson : queryString.stringify(myJson);

    if (myJson === "timeout" || path === "/auth/login-general") {
      axios.defaults.timeout = 30000;
    }

    if (getToken() !== undefined) {
      config = {
        method: methodType,
        url: `${version}${path}`,
        headers: {
          "Authorization": `${svConfig.headerType} ${getToken()}`,
          "Content-Type": svConfig.contentType,
        },
        data: dataRequest
      }
    }
    else {
      config = {
        method: methodType,
        url: `${version}${path}`,
        headers: {
          "Content-Type": svConfig.contentType,
        },
        data: dataRequest
      };
    }

    const dataPromise = await axios(config).then((response) => response?.data).catch((e) => e.response?.data);

    if (dataPromise !== undefined) {
      if (checkStatusCode(dataPromise?.code)) {
        return dataPromise;
      }

      return {
        code: 99,
        result: null
      };
    }
    else {
      return {
        code: 99,
        result: null
      };
    }
  } catch (error) {
    // console.log(error);
    // console.log(!navigator.onLine);
    if (error.message === 'No internet connection' || !navigator.onLine) {
      return {
        code: 101,
        result: null
      };
    }

    return {
      code: 99,
      result: null
    };
  }
};

export {
  apiServices
};