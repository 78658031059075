import { 
    LOAD_DATA_INVITE_ALL,
    LOAD_CLIAM_INVITE,
    LOAD_MEMBER_INVITE
} from '@core/redux/actions/affiliate/index';

const initialState = {
    inviteData: null,
    memberData: null,
    cliamData: null
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_DATA_INVITE_ALL:
            return { ...state, inviteData: action.payload.items };
        case LOAD_CLIAM_INVITE:
            return { ...state, cliamData: action.payload.items };
        case LOAD_MEMBER_INVITE:
            return { ...state, memberData: action.payload.items };
        default:
            return state;
    }
}