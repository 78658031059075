import { useNavigate } from 'react-router';

// Redux & Action
import { useSelector } from 'react-redux';

// Component
import ImageShow from "views/components/ImageShow";

export default function LogoApp() {
    const history = useNavigate();
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);

    return (
        <div onClick={() => history("/")}>
            {
                dataSetting !== null ?
                <ImageShow className="imgLogo" src={dataSetting.agent.agent_image} alt="Logo สล็อตออนไลน์" />
                :
                null
            }
            
        </div>
    );
}