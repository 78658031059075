import { useState, useEffect } from 'react';

// Redux & Action
import { useDispatch, useSelector } from 'react-redux';
import { registerCustomer } from "@core/redux/actions/auth/index";

// Component
// import RegisterMode from "./ModeMore/index";
import RegisterMode from "./ModeSingle/index";

export default function RegisterApp(props) {
    const dispatch = useDispatch();
    const [bankList, setBankList] = useState([]);
    const [showAccName, setShowAccName] = useState(false);
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const [selectBank, setSelectBank] = useState(null);

    const onSubmit = (data) => {
        dispatch(registerCustomer(data, props.close, selectBank, setShowAccName));
    };

    useEffect(() => {
        if (dataSetting !== null) {
            setBankList(dataSetting?.agent_setting_bank);
        }
    }, [dataSetting?.agent_setting_bank]);

    return (
        <>
            {
                dataSetting !== null && bankList?.length > 0  ?
                <RegisterMode {...props} bankList={bankList} onSubmit={onSubmit} showAccName={showAccName} selectBank={selectBank} setSelectBank={setSelectBank}></RegisterMode>
                :
                null
            }
        </>
        
    );
}