import { SET_CHANGE_PASSWORD } from "./../../actions/profile/index";

const initialState = {
    show: false
};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case SET_CHANGE_PASSWORD:
            return { ...state, ...action.payload.items };
        default:
            return state;
    }
}