// Redux & Action
import { useSelector } from 'react-redux';

// Component
import Grid from '@mui/material/Grid';
import ImageShow from "views/components/ImageShow";
import BackButton from "views/components/BackButton";

// Style & CSS
import "assets/css/Contact.css";
import { useEffect, useState } from 'react';

export default function Contact({ typeAction, agent_contact }) {
    const dataSetting = useSelector(state => state.reduxAppDataSetting.result);
    const homeData = useSelector(state => state.reduxHome.result);
    const [dataContact, setDataContact] = useState(null);

    const loadLiveChat = () => {
        if (window?.LiveChatWidget !== undefined) {
            window.LiveChatWidget.on('ready', onReady);
            window.LiveChatWidget.on('visibility_changed', onVisibilityChanged);
        }
    };

    function onVisibilityChanged(data) {
        let visibility = data.visibility;
        if (visibility === "minimized" || visibility === "hidden") {
            window.LiveChatWidget.call("hide");
        }
    }

    const onReady = () => {
        window.LiveChatWidget.call("maximize");
        window.LiveChatWidget.call('set_customer_name', `${homeData !== null ? homeData.username : "-"}`);
        window.LiveChatWidget.call("set_session_variables", {
            username: (homeData !== null ? homeData.username : "-"),
            balance: (homeData !== null ? homeData.balance : 0),
            phone: (homeData !== null ? (homeData.phone === "" ? "-" : homeData.phone) : "-")
        });
    };

    const callContact = (data) => {
        window.open('tel:' + data.channel_appid, "_parent");
    }

    const gotoContact = (data) => {
        if (data.contact_channel === 0 || data.contact_channel === 3) {
            window.open(data.channel_link, "_blank");
        }
        else if (data.contact_channel === 1) {
            loadLiveChat();
        }
        else {
            callContact(data);
        }
    };

    useEffect(() => {
        if (agent_contact !== undefined && agent_contact !== null) {
            setDataContact(agent_contact);
        }
        else {
            if (dataSetting?.agent_contact !== null && dataSetting?.agent_contact !== undefined) {
                setDataContact(dataSetting?.agent_contact);
            }
        }
    }, [agent_contact, dataSetting?.agent_contact]);

    return (
        <>
            {
                (typeAction === undefined || typeAction === null) ?
                    <Grid container direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                        <Grid item xs={1}>
                            <BackButton backPage="goBack"></BackButton>
                        </Grid>
                        <Grid item>
                            <div className="contactTitle center textBold">
                                ติดต่อเรา
                            </div>
                        </Grid>
                        <Grid item xs={1}>
                            &nbsp;
                        </Grid>
                    </Grid>
                    :
                    <div className="contactTitle center textBold">
                        ติดต่อเรา
                    </div>
            }

            <div className="contactTitleSub center contentMarginTop">
                มีพนักงานให้บริการตลอด 24 ชม.
            </div>

            <div className="boxMarginTop">
                {
                    (dataContact !== null) && (
                        dataContact.map((item, index) => (
                            <div key={index} className="contactMenuList positionRelative cursorPointer" onClick={() => gotoContact(item)}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={4}>
                                        <div className="center">
                                            {
                                                item.contact_channel === 0 ?
                                                    <ImageShow className="contactMenuListImg" src="/images/icon/contact_line.webp" alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                                                    :
                                                    item.contact_channel === 1 ?
                                                        <ImageShow className="contactMenuListImg" src="/images/icon/contact_livechat.webp" alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                                                        :
                                                        item.contact_channel === 3 ?
                                                            <ImageShow className="contactMenuListImg" src="/images/icon/contact_telegram.png" alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                                                            :
                                                            <ImageShow className="contactMenuListImg" src="/images/icon/contact_phone.webp" alt="ติดต่อพนักงาน สล็อตออนไลน์" />
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <div className="contactMenuListText">
                                            {
                                                item.contact_channel === 0 ?
                                                    "ติดต่อผ่านไลน์"
                                                    :
                                                    item.contact_channel === 1 ?
                                                        "ติดต่อผ่านไลฟ์แชท"
                                                        :
                                                    item.contact_channel === 3 ?
                                                        "ติดต่อผ่านเทเลแกรม"
                                                        :
                                                        `โทร ${item.channel_appid}`
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        ))
                    )
                }
            </div>
        </>
    );
}