export default function alertText(code) {
    let alertJson = {};
    if (code === 1) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ฟิลด์ข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 2) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูล ระบบกำลังเปลี่ยนเส้นทางไป tonyslot168 ในอีก 5 วินาที"
        };
    }
    else if (code === 3) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ระบบกำลังปิดปรับปรุง ขออภัยในความไม่สะดวก"
        };
    }
    else if (code === 4) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ยกเลิกให้บริการเเล้ว ระบบกำลังเปลี่ยนเส้นทางไป tonyslot168 ในอีก 5 วินาที"
        };
    }
    else if (code === 5) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูลยูสเซอร์อยู่ในระบบ กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 6) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ฟอร์แมตการยืนยันข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 7) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "รหัสผ่านไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 8) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "คุณลูกค้ากรอกรหัสผ่านไม่ถูกต้องเกินจำนวน ทำให้ระบบไม่สามารถยืนยันตัวตนได้ กรุณาลองใหม่อีกครั้งในอีก 5 วินาที"
        };
    }
    else if (code === 9) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ยืนยันรีแคปต์ชามีข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 10) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ยืนยันรีแคปต์ชาไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 11) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถทำรายการได้ เนื่องบัญชีของคุณลูกค้าโดนแบน"
        };
    }
    else if (code === 12) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถทำรายการได้ เนื่องจากบัญชีของคุณลูกค้าโดนปิดการใช้งานชั่วคราว กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 13) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ระบบไม่สามารถส่ง SMS ได้ กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 14) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "มีข้อมูลยูสเซอร์เนมอยู่ในระบบแล้ว กรุณากรอกยูสเซอร์เนมใหม่อีกครั้ง"
        };
    }
    else if (code === 15) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถลบบัญชีธุรกรรมได้ เนื่องจากบัญชีธุรกรรมของคุณลูกค้าเหลือ 1"
        };
    }
    else if (code === 16) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ข้อมูลไอดีไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 17) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "รูปแบบข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 18) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถเพิ่มบัญชีธุรกรรมได้ เนื่องจากคุณลูกค้าไม่ได้กรอก 'ชือ-นามสกุล' เข้ามาในระบบ กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 19) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูลบัญชีฝากเงินของคุณอยู่ในระบบ กรุณาเพิ่มบัญชีฝากเงินก่อนทำรายการ"
        };
    }
    else if (code === 20) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "คุณลูกค้ากรอกรหัสผ่านเก่าไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 21) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "คุณลูกค้ากรอกรหัสผ่านไม่ตรงกัน กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 22) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "คุณลูกค้ามีรายชื่อเกมนี้ อยู่ในรายการที่ชื่นชอบอยู่แล้ว"
        };
    }
    else if (code === 23) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีรายการเกมนี้อยู่ในระบบ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 24) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "หมวดหมู่เกมไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 25) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ชนิดข้อมูลไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 26) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "โหมดเกมไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 27) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "โหมดเกมไม่พร้อมใช้งาน กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 28) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีค่ายเกมนี้อยู่ในระบบ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 29) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถทำรายการได้ เนื่องจากติดสถานะรับโปรโมชั่น กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 30) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถเล่นเกมได้ เนื่องจากสร้างลิงค์สำหรับเล่นเกมไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 31) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถรับโปรโมชั่นได้ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 32) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถยกเลิกโปรโมชั่นได้ เนื่องจากเครดิตของคุณมีมากกกว่า 5 เครดิต"
        };
    }
    else if (code === 33) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูลโปรโมชั่น"
        };
    }
    else if (code === 34) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูลธนาคารที่เลือก กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 35) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีข้อมูลธนาคาร กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 36) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีบัญชีที่สามารถจับคู่ได้ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 37) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถสร้างยูสเซอร์เนมเกมได้ กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 38) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีโปรโมชั่น"
        };
    }
    else if (code === 39) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีรายการฝากเงิน"
        };
    }
    else if (code === 40) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถถอนเงินได้ เนื่องจากคุณลูกค้าไม่ผ่านเงื่อนไข"
        };
    }
    else if (code === 41) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "เครดิตของคุณลูกค้ามีไม่เพียงพอ กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 42) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถแจ้งถอนเงินได้ เนื่องจากบัญชีธนาคารปลายทางที่คุณลูกค้าเลือกไม่สามารถทำรายการได้"
        };
    }
    else if (code === 43) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ถอนเงินไม่สำเร็จ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 44) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถยกเลิกโปรโมชั่นได้ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 48) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "เข้าสู่ระบบผ่านไลน์มีระบบทำงานไม่สมบูรณ์ กรุณาลองเข้าสู่ระบบผ่านไลน์ใหม่อีกครั้ง"
        };
    }
    else if (code === 51) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "มีบัญชีธนาคารซ้ำอยู่ในระบบ กรุณาลองบัญชีอื่น"
        };
    }
    else if (code === 52) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่มีกิจกรรมให้เปิดใช้งาน กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 53) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ข้อมูลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง"
        };
    }
    else if (code === 54) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "กิจกรรมเต็มจำนวนแล้ว !"
        };
    }
    else if (code === 55) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "กิจกรรมนี้ถูกใช้งานเเล้ว"
        };
    }
    else if (code === 61 || code === 63) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถเล่นเกมได้ เนื่องจากลิงค์เกมไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else if (code === 62) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถเล่นเกมได้ เนื่องจากเกมปิดปรับปรุง"
        };
    }
    else if (code === 64) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถเล่นเกมได้ เนื่องจากค่ายเกมปิดปรับปรุง"
        };
    }
    else if (code === 65) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "กรุณาเคลียร์โปรโมชั่นให้สำเร็จ ก่อนเข้าเล่นเกม"
        };
    }
    else if (code === 66) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "มีข้อมูลเบอร์โทรศัพท์อยู่ในระบบแล้ว กรุณากรอกเบอร์โทรศัพท์ใหม่อีกครั้ง"
        };
    }
    else if (code === 68) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่พบข้อมูลบัญชีธุรกรรม กรุณากรอก ชื่อ-นามสกุล"
        };
    }
    else if (code === 69) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่พบข้อมูลบัญชีธุรกรรม กรุณาระบุข้อมูลเลขบัญชีใหม่อีกครั้ง"
        };
    }
    else if (code === 70) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถหมุนกงล้อได้ เนื่องจากแต้มสะสมมีไม่เพียงพอ"
        };
    }
    else if (code === 71) {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "ไม่สามารถหมุนกงล้อได้ กรุณาติดต่อผู้ดูแลระบบ"
        };
    }
    else {
        alertJson = {
            statusShow: true,
            statusAlert: "warning",
            titleAlert: "แจ้งเตือน",
            textAlert: "มีข้อผิดพลาดเกิดขึ้น กรุณาติดต่อผู้ดูแลระบบ"
        };
    }

    return alertJson;
}