import { forwardRef, useImperativeHandle } from 'react';
import { useDispatch } from 'react-redux';

// Redux
import { setSnackbarData } from "@core/redux/actions/app/snackbar";

const SnackbarSet = forwardRef((props, ref) => {
    const dispatch = useDispatch();

    useImperativeHandle(ref, () => ({
        snackbar(data) {
            dispatch(setSnackbarData(data));
        }
    }));

    return null;
});

export default SnackbarSet;